/* eslint-disable react/no-danger */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import SocialIcons from '../../components/social-icons/social-icons';

const renderMobileView = (data) => {
  const readMore = 'Read More >>';
  return (
    <Link to={`/news/detail/${data.id}`}>
      <div className="h-[392px] bg-neutral-100 relative px-[50px]">
        <div className="grid place-items-center">
          <div className="w-full pt-2">
            <div className="text-primary font-bold  mt-4 text-[24px]">
              <div className="grid">
                <div>{data.title}</div>
              </div>
            </div>
            <p className="text-[17px] text-neutral-400 font-normal mb-3">
              {data.publishDate}
            </p>
            <div
              className="lg:ml-[16px] text-[17px] leading-[35px] mobile:leading-[30px] font-normal mt-5 text-neutral-400 lg:line-clamp-4 mobile:line-clamp-7 sm:w-full"
              dangerouslySetInnerHTML={{
                __html: data.body?.body,
              }}
            />
            <div className="bottom-5 right-5 text-orange text-[17px] font-bold leading-[35px]">
              <Link to={`/news/detail/${data.id}`}>{readMore}</Link>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const renderDesktopView = (data) => {
  const fullPath = `https://www.hive.gov/news/detail/${data.id}`;

  return (
    <div className="justify-center flex">
      <Link to={`/news/detail/${data.id}`}>
        <div className="flex justify-center">
          <div
            className="
                xl:w-[1199px]
                lg:w-[920px]
                mobile:w-[428px]
                pb-[44px]
                mx-auto "
          >
            <div className="grid place-items-center">
              <div>
                <div className="text-primary font-bold text-[40px] mt-[44px]">
                  <div className="grid grid-cols-2">
                    <div className="w-[711px]">{data.title}</div>
                    <div className="flex justify-end">
                      <SocialIcons
                        title={data.title}
                        isDetailPage={false}
                        pageLink={fullPath}
                      />
                    </div>
                  </div>
                </div>
                <p className="text-[24px] text-neutral-400 font-normal lg:mb-6 ">
                  {data.publishDate}
                </p>
                <div
                  className="text-ellipsis lg:text-[24px] leading-[35px] mobile:leading-[30px] lg:ml-[22px] xl:text-[24px] font-normal line-clamp-4 text-neutral-400"
                  dangerouslySetInnerHTML={{
                    __html: data.body?.body,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

function NewsroomSummary({ newsarticles }) {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    if (newsarticles) {
      setArticles(newsarticles);
    }
  }, [newsarticles]);

  return (
    <div>
      {articles.map((article) => {
        return (
          <div
            className="lg:hover:cursor-pointer mt-[30px] hover:bg-neutral-100 mobile:px-[15px]"
            key={article.id}
          >
            <div>
              <div>
                <div className="lg:hidden">{renderMobileView(article)}</div>
                <div className="hidden lg:block">{renderDesktopView(article)}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default NewsroomSummary;
