import * as React from 'react';
import { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';

import sitting from '../images/headerNewsroom.svg';
import NewsroomSummary from '../page-components/newsroom/newsroomSummary';
import Pagination from '../components/pagination/pagination';

function NewsroomPage({ location, data, newsPerPage = 5 }) {
  const [newsPages, setNewsPages] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageView, setPageView] = useState([]);

  useEffect(() => {
    if (data?.allContentfulNewsroomArticle?.edges.length > 0) {
      /* eslint-disable no-param-reassign */
      const result = data.allContentfulNewsroomArticle.edges.reduce(
        (resultArray, item, index) => {
          const chunkIndex = Math.floor(index / newsPerPage);

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }

          resultArray[chunkIndex].push(item.node);

          return resultArray;
        },
        []
      );

      setNewsPages(result);
      setPageIndex(1);
    }
  }, [data]);

  useEffect(() => {
    setPageView(newsPages[pageIndex - 1]);
  }, [pageIndex, newsPages]);

  const onPageChange = (pageNumber) => {
    setPageIndex(pageNumber);
  };

  const headerContent = {
    heading: 'Newsroom',
    subheading:
      'The world of Federal Health IT is constantly evolving. Staying in the know provides an advantage. Find the latest Federal Health news here.',
    image: sitting,
    alt: 'Reading the News',
  };

  return (
    <Layout
      title="Newsroom | HIVE"
      headerContent={headerContent}
      location={location}
    >
      <div className="lg:flex lg:justify-center mobile:px-[15px] mobile:pb-[30px]">
        <div className="lg:justify-center lg:flex lg:w-11/12 xl:w-10/12">
          <div
            className=" xl:w-[1199px]
                lg:w-[920px]
                mx-auto"
          >
            <h1 className="font-bold mobile:pt-[50px] pt-10 mobile:mt-0 mt-[49px] ml-0 mobile:text-center ml-0 -mb-4 lg:mb-14 text-neutral-400 lg:text-[24px] text-[18px]">
              Latest News
            </h1>
          </div>
        </div>
      </div>

      <NewsroomSummary newsarticles={pageView} />
      {newsPages.length > 1 && (
        <div className="flex flex-col justify-center items-center sm:mt-[130px] lg:mt-[148px] mobile:px-[15px]">
          <Pagination
            onPageChange={(index) => onPageChange(index)}
            totalCount={data.allContentfulNewsroomArticle.totalCount}
            pageSize={newsPerPage}
            currentPage={pageIndex}
          />
        </div>
      )}

      <div className="sm:pb-[90px] lg:pb-[100px] mobile:px-[15px]" />
    </Layout>
  );
}

export default NewsroomPage;

export const pageQuery = graphql`
  {
    allContentfulNewsroomArticle(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          body {
            body
            childMarkdownRemark {
              html
            }
          }
          publishDate(formatString: "MM/DD/YYYY")
          title
        }
      }
      totalCount
    }
  }
`;
